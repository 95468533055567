import Transformer from '@/transformers/Transformer'
import type { ItemData } from '@/types/Models/Item'

export default class ItemTransformer extends Transformer {
  /**
   * Method used to transform a fetched Assignment's Item.
   */
  static fetch(item: ItemData): ItemData {
    if (!item) {
      return {}
    }
    const itemData = {
      weight: parseInt(item.weight, 10),
      width: parseInt(item.width, 10),
      height: parseInt(item.height, 10),
      length: parseInt(item.length, 10),
      estimatedCost: parseInt(item.estimatedCost || 0, 10),
      recipientAddress: item.recipientAddress || {},
      products: item.products || [],
      validationFlags: [],
    }
    if (Array.isArray(item.validationFlags)) {
      itemData.validationFlags =
        item.validationFlags.reduce((acc, cv) => {
          acc[cv.field] = { level: cv.level, reason: cv.reason }
          return acc
        }, {}) || {}
    }
    if (itemData.products.length) {
      ;[itemData.product] = item.products
    }
    return { ...item, ...itemData } as ItemData
  }

  static create(item) {
    const itemData = { ...item }
    delete itemData.parcel_characteristics

    itemData.estimated_cost = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.estimated_cost, 0)
    itemData.weight = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.weight, 0)
    itemData.height = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.height, 0)
    itemData.length = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.length, 0)
    itemData.width = item.parcel_characteristics.reduce((acc, cv) => acc + +cv.width, 0)
    itemData.products = item.parcel_characteristics.map((product) => ({
      weight: product.weight * 1,
      width: product.width * 1,
      height: product.height * 1,
      length: product.length * 1,
      estimatedCost: product.estimated_cost * 1,
      description: product.description,
      commodityCode: product.commodity_code,
      quantity: 1,
    }))
    if (item.parcel_characteristics.length > 0 && item.parcel_characteristics[0].description) {
      itemData.reference = item.parcel_characteristics[0].description
    }

    return itemData
  }
}
