import ItemsProxy from '@/proxies/ItemsProxy'
import ItemProductProxy from '@/proxies/ItemProductProxy'
import ItemAddressesProxy from '@/proxies/ItemAddressesProxy'

export const RESET_CHECKED = 'RESET_CHECKED'
export const SET_CHECKED = 'SET_CHECKED'
export const SET_ERRORS_ONLY = 'SET_ERRORS_ONLY'
export const SET_LABELS_ERRORS_ONLY = 'SET_LABELS_ERRORS_ONLY'
export const SET_FATAL_ERROR_COUNT = 'SET_FATAL_ERROR_COUNT'

const state = {
  checkedItems: [],
  errorsOnly: false,
  labelsErrorsOnly: false,
  fatalErrorsCount: 0,
}

const getters = {
  checkedItems: (state) => state.checkedItems,
  errorsOnly: (state) => state.errorsOnly,
  labelsErrorsOnly: (state) => state.labelsErrorsOnly,
  fatalErrorsCount: (state) => state.fatalErrorsCount,
}

const mutations = {
  [RESET_CHECKED](state) {
    state.checkedItems = []
  },
  [SET_CHECKED](state, checked) {
    state.checkedItems = checked
  },
  [SET_ERRORS_ONLY](state, checked) {
    state.errorsOnly = checked
  },
  [SET_LABELS_ERRORS_ONLY](state, checked) {
    state.labelsErrorsOnly = checked
  },
  [SET_FATAL_ERROR_COUNT](state, count) {
    state.fatalErrorsCount = count
  },
}

const actions = {
  setFatalErrorCount: ({ commit }, count) => {
    commit(SET_FATAL_ERROR_COUNT, count)
  },
  setErrorsOnly: ({ commit }, checked) => {
    commit(SET_ERRORS_ONLY, checked)
  },
  setLabelsErrorsOnly: ({ commit }, checked) => {
    commit(SET_LABELS_ERRORS_ONLY, checked)
  },
  updateItem: (_, { item }) => new ItemsProxy().update(item.id, item),
  updateItemAddress: (_, { id, itemAddressField }) => new ItemAddressesProxy().update(id, itemAddressField),
  updateItemProduct: (_, { id, itemProductField }) => new ItemProductProxy().update(id, itemProductField),
  validateItem: ({ dispatch }, itemId) =>
    new ItemsProxy().validate(itemId).then((request) => {
      dispatch(`assignment/fetch`, null, { root: true })
      return request.data
    }),
  // items list checked items
  updateCheckedItems: ({ commit }, checked) => {
    commit(SET_CHECKED, checked)
  },
  resetCheckedItems: ({ commit }) => {
    commit(RESET_CHECKED)
  },
  deleteCheckedItems: (_, { items }) => {
    const promisesArray = []
    items.forEach((id) => {
      promisesArray.push(new ItemsProxy().destroy(id))
    })
    return Promise.all(promisesArray)
  },
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
