import Axios from '@/services/Axios'

export interface ProxyMethods<T = any> {
  submit(requestType: string, url: string, data?: object | null, options?: object): Promise<T | any>
  all(): Promise<T | any>
  find(id: string | number): Promise<T | any>
  create(item: object | FormData, options?: object): Promise<T | any>
  update(id: string | number, item: object): Promise<T | any>
  updateField(id: string, field: string, value: any): Promise<T | any>
  destroy(id: string): Promise<T | any>
  endpoint: string
}

export function applyProxyMethodsMixin<TBase extends new (...args: any[]) => object, T = any>(Base: TBase) {
  return class extends Base implements ProxyMethods<T> {
    public endpoint!: string
    public parameters: object

    constructor(params?: object = {}) {
      super(params)
      this.parameters = params
    }

    submit<R = T | any>(
      requestType: string,
      url: string,
      data: object | null = null,
      options: object = {},
    ): Promise<R> {
      const http = Axios
      const clearedUrl = url.replace(/\/$/, '')
      return new Promise((resolve, reject) => {
        http[requestType](clearedUrl + this.getParameterString(), data, options)
          .then((response) => {
            resolve(response.data as R)
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data)
            } else {
              reject(e)
            }
          })
      })
    }

    getParameterString(): string {
      const params = { ...this.parameters }
      const keys = Object.keys(params)
      const parameterStrings = keys
        .filter((key) => !!params[key] || params[key] === 0)
        .map((key) => `${key}=${params[key]}`)

      return parameterStrings.length === 0 ? '' : `?${parameterStrings.join('&')}`
    }

    all(): Promise<T | any> {
      return this.submit('get', `/${this.endpoint}`)
    }

    find(id: string | number): Promise<T | any> {
      return this.submit('get', `/${this.endpoint}/${id}`)
    }

    create(item: object | FormData, options: object = {}): Promise<T | any> {
      return this.submit('post', `/${this.endpoint}`, item, options)
    }

    update(id: string | number, item: object): Promise<T | any> {
      return this.submit('put', `/${this.endpoint}/${id}`, item)
    }

    updateField(id: string, field: string, value: any): Promise<T | any> {
      const data = { [field]: value }
      return this.submit('patch', `/${this.endpoint}/${id}`, data)
    }

    destroy(id: string): Promise<T | any> {
      return this.submit('delete', `/${this.endpoint}/${id}`)
    }
  }
}
