import { applyProxyMethodsMixin, ProxyMethods } from '@/proxies/Proxy'
import type { PackingSlip, PackingSlipItemCollection } from '@/types/Models/PackingSlip'

export default class PackingSlipProxy extends applyProxyMethodsMixin(class {}) implements ProxyMethods<PackingSlip> {
  public endpoint = 'packing-slips'

  getItems(id: string): Promise<PackingSlipItemCollection> {
    return this.submit('get', `/${this.endpoint}/${id}/items`)
  }

  removeItems(id: string, items: string[]): Promise<any> {
    return this.submit('post', `/${this.endpoint}/${id}/remove-items`, { items })
  }
}
